
import { getStartAndEndTimestampsForDay } from '../utils/getStartAndEndTimestampsForDay'



function isValidDate(day, month, year) {
  // Convert parameters to integers
  day = parseInt(day, 10);
  month = parseInt(month, 10);
  year = parseInt(year, 10);

  // Check if any of the parameters are invalid numbers
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  // JavaScript months are 0-based (0 = January, 11 = December), so adjust month to 0-based index
  month -= 1;

  // Create a Date object with the given parameters
  const date = new Date(year, month, day);

  // Check if the created date matches the given parameters
  if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
    return false;
  }

  // Get today's date without the time component
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Check if the given date is in the future
  if (date > today) {
    return false;
  }

  // If all checks pass, the date is valid and not in the future
  return true;
}


export const sortChecklistDataByTimePeriod = (tableData, month, year, checklistId) => {

  const currentDate = new Date()
  let year_to_search = year
  if (!year) {
    year_to_search = currentDate.getFullYear()
  }
  let month_to_search = month
  if (!month) {
    month_to_search = currentDate.getMonth() + 1
  }

  const timeZone = tableData.Organisation.TimeZone || 'Europe/London'

  // loop through the days this month
  const newChecklistPeriodsByDay = {}
  for (let day = 1; day <= 31; day++) {

    if (isValidDate(day, month_to_search, year_to_search)) {

      const dateString = `${year_to_search}-${`${month_to_search}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
      const { startTimestamp, endTimestamp } = getStartAndEndTimestampsForDay(dateString, timeZone)
      const checklistPeriodsForThisDay = {}
      // find check periods for this day
      for (const key in tableData['ChecklistPeriods']) {
        const thisCheckPeriod = tableData['ChecklistPeriods'][key]
        console.log(thisCheckPeriod)
        if (
          ((checklistId && thisCheckPeriod['ChecklistId'] === checklistId) || !checklistId) &&
          parseInt(thisCheckPeriod['StartTimeStamp']) >= startTimestamp 
          &&
          parseInt(thisCheckPeriod['StartTimeStamp']) <= endTimestamp
        ) {
          checklistPeriodsForThisDay[key] = tableData['ChecklistPeriods'][key]
        }
      }

      if (Object.keys(checklistPeriodsForThisDay).length > 0) {
        newChecklistPeriodsByDay[day] = checklistPeriodsForThisDay
      } else {
        newChecklistPeriodsByDay[day] = {}
      }
    }
  }

  return newChecklistPeriodsByDay
}